import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-oli-checkout"
    }}>{`Getting Started Oli-Checkout`}</h1>
    <p><em parentName="p">{`Integrations API from multiples platforms`}</em></p>
    <hr></hr>
    <h2 {...{
      "id": "requirements"
    }}>{`Requirements`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Domain page administrator
- X-Shopify-Access-Token
`}</code></pre>
    <h2 {...{
      "id": "permissions-keys"
    }}>{`Permissions keys`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Hook orders
- Draft_orders
- Orders
- Products
- Inventory
`}</code></pre>
    <h2 {...{
      "id": "routes-integrations"
    }}>{`Routes Integrations`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Router GET - /admin/api/2022-01/products.json
- Router GET - /admin/api/2022-01/draft_orders.json
- Router GET - /admin/api/2021-10/products/123321132/variants.json
- Router GET - /admin/api/2021-10/draft_orders/1233211.json
- Router GET - /admin/api/2021-10/products/1233211123.json
- Router GET - /admin/api/2020-04/products.json?title=<title product>
- Router POST - /admin/api/2022-01/webhooks.json
- Router GET - /admin/api/2022-01/webhooks.json
- Router DELETE - /admin/api/2022-01/webhooks/123321123.json
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "cart-checkout"
    }}>{`Cart Checkout`}</h2>
    <p><em parentName="p">{`Simple generate url to redirect users from shopping cart using identification Utm_Source.`}</em></p>
    <h3 {...{
      "id": "post-router"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/cart`}</em></p>
    <h4 {...{
      "id": "request"
    }}>{`Request:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "items": {
    "id": "string",
    "quantity": 0,
  },
  "clientProfileData": {
    "liveId": "fake-ab1c-4b59-a65e-38283984ed46"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response"
    }}>{`Response:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "message": "string",
  "status": "string",
  "data": {
    "url": "https://domainClient.vtexcommercestable.com.br/checkout/cart/add?sku=1233&qty=1&seller=1&sku=1233&qty=1&seller=1&utm_source=Oli_DayMonthYear
  }
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "live-sharing-initialize-or-finish"
    }}>{`Live sharing initialize or finish`}</h2>
    <p><em parentName="p">{`Initialize live configure webhook order.`}</em></p>
    <h3 {...{
      "id": "post-router-1"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/status/live`}</em></p>
    <h4 {...{
      "id": "request-1"
    }}>{`Request:`}</h4>
    <p><em parentName="p">{`Example start live`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "isNewStartLive": true,
    "clientProfileData": {
        "liveId": "fake-ab1c-4b59-a65e-38283984ed46",
    }
}
`}</code></pre>
    <h4 {...{
      "id": "response-1"
    }}>{`Response:`}</h4>
    <p><em parentName="p">{`Response start live`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "message": null,
    "statusCode": 201,
    "status": "CREATED",
}
`}</code></pre>
    <h4 {...{
      "id": "request-2"
    }}>{`Request:`}</h4>
    <p><em parentName="p">{`End live delete webhook`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "isNewStartLive": false,
    "clientProfileData": {
        "liveId": "fake-ab1c-4b59-a65e-38283984ed46"
    }
}
`}</code></pre>
    <h4 {...{
      "id": "response-2"
    }}>{`Response:`}</h4>
    <p><em parentName="p">{`Example end live sharing`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "message": null,
    "statusCode": 200,
    "status": "SUCCESS",
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "get-products"
    }}>{`Get products`}</h2>
    <p><em parentName="p">{`Choice products from live`}</em></p>
    <h3 {...{
      "id": "post-router-2"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/get/products`}</em></p>
    <h4 {...{
      "id": "request-3"
    }}>{`Request:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "keyword": "title product",
    "clientProfileData": {
        "liveId": "1b929f5d-ab1c-4b59-a6e5-38283984ed46"
    }
}
`}</code></pre>
    <h4 {...{
      "id": "response-3"
    }}>{`Response:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "data": [
        {
            "product": {
                "productId": "19356",
                "title": "Camisa Example in store",
                "description": "Description keyWord",
                "Items": [
                    {
                        "images": [
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            },
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            },
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            },
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            }
                        ],
                        "skuId": "23326",
                        "variantName": "Camisa Example in store - P",
                        "cores": [
                            "Rosa"
                        ],
                        "size": [
                            "P"
                        ]
                    },
                    {
                        "images": [
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            },
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            },
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            },
                            {
                                "imageUrl": "https://store/arquivos/ids/166128/image.jpg?v=637595567273300000"
                            }
                        ],
                        "skuId": "19357",
                        "variantName": "Camisa Example in store - M",
                        "cores": [
                            "Rosa"
                        ],
                        "size": [
                            "M"
                        ]
                    }
                ]
            }
        }
    ],
    "status": "SUCCESS",
    "statusCode": 200
}
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "sold-hook"
    }}>{`Sold Hook`}</h2>
    <p><em parentName="p">{`Sold product alert live`}</em></p>
    <h3 {...{
      "id": "socket-emit-soldinlive"
    }}>{`Socket Emit: `}<em parentName="h3">{`soldInLive`}</em></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
    "data": {
        "id": "5abb01e3-0637-4029-b78b-179315796f48",
        "fullName": "JulianaWerner"
    },
    "status": "SUCCESS",
    "statusCode": 200
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      